.App {
  text-align: center;
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.AppNav-logo {
  display: inline-block;
  height: 3vmax;
  vertical-align: middle;
  color: #A0B0A0;
  font-stretch: condensed;
  vertical-align: middle;
  font-size: 2vmax;
  margin-top: -3px;
  padding: 0;
  text-align: left;
  margin-left: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: min(calc(10px + 2vmin), 16px);
  color: white;
}

.App-content {
  background-color: #282c34;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  font-size: min(calc(10px + 2vmin), 16px);
  color: white;
}

.AppNav {
  background-color: #1d2027;
  padding: 5px;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.AppNav li, a { 
  display: inline;
  margin-left: 10px;
  color: #61dafb;
}
.AppNav ul { 
  margin: 0;
  display: inline-block;
}

.AuthButton {
  display: inline-block;
  text-align: right;
  color: #E0E0E0;
}

.App-link {
  color: #61dafb;
}

.RefreshAccountsLink {
  text-align: left;
  font-size: min(calc(8px + 2vmin), 14px);
  margin: 10px 10px;
}

.AccountList {
  text-align: left;
  margin-left: 10px;
}

.AccountItem h2 {
  display: block;
  margin-bottom: 0;
  color: hsl(180, 27%, 71%)
}

.AccountItem .Details p {
   display: block;
   margin: 0;
   color: #A0B0A0
}

.AccountItem .Details p span {
  color: hsl(180, 27%, 71%)
}

.AccountItem .Details ul {
  margin: 0;
  padding-left: 1.2em;
  list-style-type: none;
}

.AccountItem .Details ul li {
  line-height: 14px;
}

.AccountItem .Details ul li::before {
  content: "\1f511";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
  margin-right: 0.5em;
}

.AccountItem .Details ul li span {
  color: #A0B0A0
}

.AccountItem .Details div.TempCredentials {
  margin: 0;
  background-color: #333; 
  margin: 5px;
  max-width: 700px;
  box-shadow: 2px 2px 2px 2px rgba(8, 8, 8, 0.2);
  padding: 2px;
  color: #888;
}
.AccountItem .Details div.TempCredentials p {
  color: #888;
}

.AccountItem .Details div.TempCredentials blockquote {
  border-left: 3px solid #888;
  margin: 0;
  margin-left: 10px;
}

.AccountItem .Details div.TempCredentials blockquote pre {
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  font-size: 12px;
  margin-top: 0px;
  margin-bottom: 10px;
  overflow: scroll;
  width: auto;
  padding: 5px;
  background-color: #111;
  width: 650px;
  padding: 10px;
  color: #888;
  border: 2px solid #333;
}

code {
  white-space: pre;
}

.LoginEmail {
  width: 300px;
}

.LoginButton {
  width: 350px;
  text-align: center;
  margin: 5px auto;
}

div.PrivacyPolicy {
  text-align: justify;
  margin: 20px 60px;
  border: 1px solid #333;
  padding: 10px 40px;
  color: #333;
  background-color: #ccc;
}

div.PrivacyPolicy a {
  color: #000;
  font-weight: 500;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
